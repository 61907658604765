const version = 1;
const milestone = 0;
const build = 210;

function getVersion() {
    return `${version}.${milestone}.${build}`;
}

export {
    version,
    milestone,
    build,
    getVersion
};